import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

import { ALL_STEPS, QUESTIONS } from "./constants";
// import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";

const wizardApi = axios.create({
  // baseURL: API,
  baseURL: "https://api.ims-fc.gr",
});

const modifyResponses = (responses, questions) => {
  const items = Object.entries(responses).map(([k, v]) => ({
    "ItemId": k,
    "AnswerId": "",
    ...v
  }));

  if (questions) {
    items.forEach((item) => {
      item.ItemDescription = questions[item.ItemId].title;
    })
  }

  return items;
};

const defaultResponses = {};
Object.entries(QUESTIONS).forEach(([k, v]) => {
  if (v.default) defaultResponses[k] = v.default;
})

const initialState = {
  data: [],
  userData: {
    name: null,
    email: null,
    phone: null,
    phonecode: null,
  },
  steps: ALL_STEPS,
  currentStep: 0,
  responses: defaultResponses,
  validation: null,
  summary: null,
  importCompleted: {
    success: false,
    withPassword: false,
  },
};

const WizardContext = createContext(initialState);

const WizardProvider = ({ children }) => {
  const { isLoggedIn, token } = useUserData();

  const [data, setData] = useState(initialState.data);
  const [userData, setUserData] = useState(initialState.userData);
  const [steps, setSteps] = useState(initialState.steps);
  const [currentStep, setCurrentStep] = useState(initialState.currentStep);
  const [responses, setResponses] = useState(initialState.responses);
  const [validation, setValidation] = useState(initialState.validation);
  const [summary, setSummary] = useState(initialState.summary);
  const [importCompleted, setImportCompleted] = useState(initialState.importCompleted);

  const authWizard = useCallback(() => {
    if (isLoggedIn) {
      return new Promise((resolve) => {
        wizardApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        resolve();
      });
    }

    return wizardApi
      .post("/auth-wizard", {
        "RequestToken": "Ergol0g1c2024"
      })
      .then((response) => {
        const { AccessToken } = response.data
        wizardApi.defaults.headers.common["Authorization"] = `Bearer ${AccessToken}`;
      })
      .catch((error) => {
        enqueueSnackbar(
          handleAxiosErrors(error, error.response.data.message),
          { variant: "error" }
        );
      });
  }, [isLoggedIn, token]);

  const getData = useCallback(() => {
    return wizardApi
      .get("/wizard/data")
      .then((response) => {
        if (!response.data) return;

        const { name, email, phone, phonecode } = response.data;
        setUserData({ name, email, phone, phonecode });
        setData(response.data.items);
      })
      .catch((error) => {
        enqueueSnackbar(
          handleAxiosErrors(error, error.response.data.message),
          { variant: "error" }
        );
      });
  }, []);

  const updateResponse = useCallback((itemId, value) => {
    setResponses((prev) => {
      // When there is no value, remove existing response
      if (!value) {
        const values = { ...prev };
        delete values[itemId];
        return values;
      }

      // Add new response
      return ({
        ...prev,
        [itemId]: value,
      });
    });
  }, []);

  const calculateResponses = useCallback(() => {
    return wizardApi
      .post("/wizard/calculate", {
        items: modifyResponses(responses)
      })
      .then((response) => {
        setSummary(response?.data);
      })
      .catch((error) => {
        enqueueSnackbar(
          handleAxiosErrors(error, error.response.data.message),
          { variant: "error" }
        );
      })
  }, [responses]);

  const validateResponses = useCallback(({ onSuccess }) => {
    return wizardApi
      .post("/wizard/validate", {
        validation: steps[currentStep].validation,
        items: modifyResponses(responses)
      })
      .then(() => {
        if (typeof onSuccess === "function") onSuccess();
      })
      .catch((error) => {
        setValidation(error.response?.data);
      });
  }, [currentStep, responses, steps]);

  const validateUser = useCallback(({ email, onSuccess }) => {
    if (!email) return;

    return wizardApi
      .post("/wizard/validate/user", {
        email
      })
      .then((response) => {
        if (typeof onSuccess === "function") onSuccess(response);
      })
      .catch((error) => {
        enqueueSnackbar(
          handleAxiosErrors(error, error.response.data.message),
          { variant: "error" }
        );
      });
  }, []);

  const importUser = useCallback(({ items = [], code }) => {
    const data = {
      items: [
        ...modifyResponses(responses, QUESTIONS),
        ...items,
      ],
      "Code": code || "",
    };

    return wizardApi
      .post("/wizard/import", data)
      .then(() => {
        setImportCompleted({
          success: true,
          withPassword: Boolean(
            items.find((item) => item.ItemId === "20")?.AnswerDescription
          ),
        });
      })
      .catch((error) => {
        enqueueSnackbar(
          handleAxiosErrors(error, error.response.data.message),
          { variant: "error" }
        );
      });
  }, [responses]);

  const resetSummary = useCallback(() => {
    setSummary(initialState.summary);
  }, []);

  useEffect(() => {
    authWizard().then(() => {
      getData();
    });
  }, [authWizard, getData]);

  // Set wizard steps based on answers
  useEffect(() => {
    const activeSteps = ALL_STEPS.filter((step) => step.active(responses));
    setSteps(activeSteps);
    setValidation(initialState.validation);
  }, [responses]);

  // Change screens
  useEffect(() => {
    setValidation(initialState.validation);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [currentStep, summary, importCompleted])


  const value = useMemo(() => ({
    currentStep,
    setCurrentStep,
    steps,
    setSteps,
    data,
    userData,
    responses,
    updateResponse,
    validateResponses,
    validation,
    calculateResponses,
    summary,
    resetSummary,
    validateUser,
    importUser,
    importCompleted,
  }), [
    currentStep,
    steps,
    data,
    userData,
    responses,
    updateResponse,
    validateResponses,
    validation,
    calculateResponses,
    summary,
    resetSummary,
    validateUser,
    importUser,
    importCompleted,
  ]);

  return (
    <WizardContext.Provider value={value}>
      {children}
    </WizardContext.Provider>
  )
}

const useWizard = () => {
  const context = useContext(WizardContext);

  if (context === undefined) {
    throw new Error("useWizard must be used within WizardProvider");
  }

  return context;
}

export { WizardContext, WizardProvider, useWizard };
